import axios from 'axios';

const url = 'https://api.fideleco.com.br/api/v1';

const getToken = () => {
  const token = JSON.parse(localStorage.getItem(`Hotsite:Token`));

  return token;
};

const api = axios.create({
  baseURL: url,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
